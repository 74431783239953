import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getColor, getTextType, mq } from '@stylesheets/settings'

const StyledHeadline = styled.h2`
  padding: 5px 20px;
  display: inline-block;
  background: ${getColor('yellow')};
  color: ${getColor('black')};
  ${getTextType('h1', 'bold')};
  font-weight: normal;
  text-transform: uppercase;

  .highlight {
    ${getTextType('h1', 'bold')};
  }

  ${({ variant }) => variant === 'black' && `
    background: ${getColor('black')};
    color: ${getColor('white')};
  `}

`;

function Headline ({ regularText, highlightText, variant }) {
  return (
    <StyledHeadline variant={variant}>
      {regularText} <span className="highlight">{highlightText}</span>
    </StyledHeadline>
  );
};

Headline.propTypes = {
  regularText: PropTypes.string,
  highlightText: PropTypes.string,
  variant: PropTypes.string,
}

export default Headline;