import React from 'react';
import PropTypes from 'prop-types'
import styled from "styled-components"
import { getGridSpans, mq } from '@stylesheets/settings'

import Headline from '../elements/headline'
import Copy from '../elements/copy'

const StyledWrapper = styled.div`
  ${getGridSpans('width', { s:5, m: [4, 'full'], l: 6 })}
  min-height: 150px;
  margin: 0 auto;
  text-align: center;
  padding: 25px 20px;

  ${mq('m')} {
    padding: 50px 0;
  }

  ${mq('l')} {
    padding: 50px 0;
  }

  a {
    text-decoration: underline;
  }
`;

const About = ({ headline, copy }) => {
  return (
    <StyledWrapper>
      { headline.regular.length > 0 && headline.bold.length > 0 && (
        <Headline regularText={headline.regular} highlightText={headline.bold} />
      )}
      <Copy color={'black'}>
        {copy}
      </Copy>
    </StyledWrapper>
  );
}

About.defaultProps = {
  headline: {regular: '', bold: ''},
  copy: '',
};

About.propTypes = {
  headline: PropTypes.shape({regular: '', bold: ''}),
  copy: PropTypes.string,
};

export default About;