import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getColor, getTextType } from '@stylesheets/settings'

const StyledCopy = styled.p`
  color: ${props => getColor(props.color)};
  text-align: ${props => props.alignment};
  ${getTextType('c13')}

  strong {
    ${getTextType('c13', 'bold')}
  }

  .nobr {
    white-space: nowrap;
  }
`;

function Copy ({ children, color, alignment, className }) {
  return (
    <StyledCopy 
      className={className}
      color={color} 
      alignment={alignment} 
      dangerouslySetInnerHTML={{ __html: children }}>
    </StyledCopy>
  );
};

Copy.defaultProps = {
  color: 'black',
  alignment: 'center'
}

Copy.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  alignment: PropTypes.string,
}

export default Copy;