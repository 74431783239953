import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { mq, getColor } from '@stylesheets/settings';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slideshow.css"

const StyledSlider = styled(Slider)`
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 50%;
  height: 100vh;
  
  ${mq('l')} {
    width: 25%;
  }

  animation: 0.5s ease 0s normal forwards 1 fadein;
  @keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }

  @-webkit-keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }
`;

/*const SlideItem = styled.div`
  width: 100%;
  color: #ffffff;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  background: ${getColor('black')};
  background: url(${props => props.image }) 100% no-repeat;
`;*/

const properties = {
  duration: 3000,
  transitionDuration: 500,
  autoplay: true,
  infinite: true,
  arrows: false,
  lazyLoad: true,
}

const Slideshow = ({ className, slides,  }) => {
  return (
    <StyledSlider {...properties} className={className}>
      {slides.map((image, i) => {    
          return (
            /*<SlideItem key={`${image}-${i}`} image={image} />*/
            <div key={`${image}-${i}`}className="each-slide">
              <div style={{'backgroundImage': `url(${image})`}} />
            </div>
          );
      })}
    </StyledSlider>
  )
}

Slideshow.defaultProps = {
  slides: [],
  className: ''
};

Slideshow.propTypes = {
  slides: PropTypes.array,
  className: PropTypes.string,
};

export default Slideshow;