import React from 'react';
import styled from "styled-components"
import { Link } from 'gatsby-plugin-intl'
import { getGridSpans, mq } from '@stylesheets/settings'

import GalleryImage1 from '../../static/images/gallery/unhate_01_small.jpg'
import GalleryImage2 from '../../static/images/gallery/unhate_02_small.jpg'
import GalleryImage3 from '../../static/images/gallery/unhate_03_small.jpg'
import GalleryImage4 from '../../static/images/gallery/unhate_04_small.jpg'
import GalleryImage5 from '../../static/images/gallery/unhate_05_small.jpg'
import GalleryImage6 from '../../static/images/gallery/unhate_06_small.jpg'
import GalleryImage7 from '../../static/images/gallery/unhate_07_small.jpg'
import GalleryImage8 from '../../static/images/gallery/unhate_08_small.jpg'
import GalleryImage9 from '../../static/images/gallery/unhate_09_small.jpg'

const GalleryWrapper = styled.div`
  ${getGridSpans('width', { s:6, m: 6, l: 10 })}
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
`;

const GalleryRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const GalleryItem = styled(Link)`
  flex: auto;
  position: relative;
  width: 90%;
  flex-basis: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  
  img {
    width: 100%;
    user-drag: none;
    user-select: none;
  }

  img:hover {
    transition: all .5s ease;
    transform: scale(1.02);
  }

  ${mq('s')} {
    flex-basis: 100%;
  }

  ${mq('m')} {
    flex-basis: 50%;
  }

  ${mq('l')} {
    flex-basis: 33.33%;
  }

`;

const Gallery = ({ noLinks = false}) => {
  return (
    <>
      <GalleryWrapper>
        <GalleryRow>
          <GalleryItem disabled={noLinks} to="/motiv/1"><img data-src={GalleryImage1} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/2"><img data-src={GalleryImage2} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/3"><img data-src={GalleryImage3} className="lazyload"/></GalleryItem>
        </GalleryRow>
        <GalleryRow>
          <GalleryItem disabled={noLinks} to="/motiv/4"><img data-src={GalleryImage4} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/5"><img data-src={GalleryImage5} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/6"><img data-src={GalleryImage6} className="lazyload"/></GalleryItem>
        </GalleryRow>
        <GalleryRow>
          <GalleryItem disabled={noLinks} to="/motiv/7"><img data-src={GalleryImage7} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/8"><img data-src={GalleryImage8} className="lazyload"/></GalleryItem>
          <GalleryItem disabled={noLinks} to="/motiv/9"><img data-src={GalleryImage9} className="lazyload"/></GalleryItem>
        </GalleryRow>
      </GalleryWrapper>
    </>
  );
}

export default Gallery;