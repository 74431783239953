import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { getGridSpans, mq } from '@stylesheets/settings'

import Headline from '../elements/headline'
import Copy from '../elements/copy'
import VideoLoop from "../../static/TDF_loop.mp4" 
import PlayButtonIcon  from '../../static/icons/play.svg'

const StyledPlayer = styled.div`
  ${getGridSpans('width', { s: 6, m: [6, 'full'], l: 10 })}
  position: relative;
  display: flex;
  margin: 0 auto;

  .caseVideo {
    width: 100%;
    height: 100%;
  }
`

const PlayButton = styled.button`
  position: absolute;
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const PlayIcon = styled.img`
  align-self: center;
  width: 60px;

  ${mq('m')} {
    width: 90px;
  }
`;

const StyledYTFrame = styled.div`
  flex-grow: 1;
  position: relative;
  padding-bottom: 56.25%;
`

const Overlay = styled.div`
  position: absolute; 
  display: flex; 
  justify-content: flex-end;
  flex-direction: column; 
  width: 100%; 
  height: 100%; 
  top: 0; 
  left: 0;
  cursor: pointer;
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;

  p {
    color: #000000;
  }

  ${mq('m')} {
    margin-bottom: 40px;
    p {
      color: #ffffff;
    }
  }
`;

const StyledCopy = styled(Copy)`
  display: none;
  ${mq('m')} {
    display: inline-block;
  }
`;

function VideoPlayer ({ ytSrc, headline, copy, isYT = true, url }) {

  const [isYTVisible, setYTVisible] = useState(false);

  const playVideo = () => {
    setYTVisible(true);
  }

  return (
    <StyledPlayer>
      { !isYTVisible ? (
        <>
          <section style={{position: 'relative', width: '100%'}} onClick={playVideo}>
            <div>
              <video style={{display: 'block', width: '100%'}} width="100%" autoPlay loop muted playsInline>
                <source src={VideoLoop} type="video/mp4" />
                {/*<source src="movie.ogg" type="video/ogg" />*/}
              </video>
              <PlayButton>
                <PlayIcon src={PlayButtonIcon}/>
              </PlayButton>
              {isYT && (
                <Overlay>
                  <OverlayContent>
                    <Headline regularText={headline.regular} highlightText={headline.bold} />
                    <StyledCopy color={'white'}>
                      {copy}
                    </StyledCopy>
                  </OverlayContent>
                </Overlay>
              )}
            </div>
          </section>
        </>
      ) : (
        <>
        {isYT ? (
          <StyledYTFrame>
            <iframe
              style={{position: 'absolute', height: '100%', width: '100%'}}
              src={`${ytSrc}?autoplay=1&rel=0`}
              title={'Terre des femmes | #UNHATEWOMEN'}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen={true}
              mozallowfullscreen={true}
              allowFullScreen
            />
          </StyledYTFrame>
        ) : (
        <video
          className="caseVideo"
          autoPlay={true}
          preload="metadata"
          playsInline
          controls={true}
          src={url}>
        </video>
        )}
        </>
      )}
    </StyledPlayer>
  );
};

VideoPlayer.propTypes = {
  ytSrc: PropTypes.string,
}

VideoPlayer.defaultProps = {
  ytSrc: `https://www.youtube.com/embed/C4d8rmS6IBc`,
}

export default VideoPlayer
